<div mat-dialog-title class="modification-dialog-title">
  <p class="fw-600">New Modification Request</p>
  <button mat-button class="save" (click)="onSubmit()">Save & Exit</button>
</div>
<mat-dialog-content class="modification-dialog-content">
  <form [formGroup]="form">
    <div class="date-pickers-container" style="display: flex; flex-wrap: wrap">
      <div class="w-40 mb-20px" style="flex: 1; margin-right: 20px">
        <app-roam-datepicker
          name="Start Date"
          formControlName="startDate"
        ></app-roam-datepicker>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('startDate')?.touched &&
              form.get('startDate')?.hasError('required')
            "
          >
            Start date is required
          </p>
        </mat-error>
      </div>

      <div class="w-40 mb-20px" style="flex: 1">
        <app-roam-datepicker
          name="Completion Date"
          formControlName="completionDate"
        ></app-roam-datepicker>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('completionDate')?.touched &&
              form.get('completionDate')?.hasError('required')
            "
          >
            Completion date is required
          </p>
        </mat-error>
      </div>
    </div>

    <div
      *ngIf="userRole === 'manager'"
      class="association-account-container"
      style="display: flex; flex-wrap: wrap"
    >
      <div class="w-40 mb-20px" style="flex: 1; margin-right: 20px">
        <app-roam-select
          placeholder="Association"
          [items]="associationsList"
          className="radius-10"
          formControlName="propertyId"
          [searchable]="true"
          (selectedItem)="selectedAssociationChange($event)"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('propertyId')?.touched &&
              form.get('propertyId')?.hasError('required')
            "
          >
            Association is required
          </p>
        </mat-error>
      </div>

      <div class="w-40 mb-20px" style="flex: 1">
        <app-roam-select
          placeholder="Address"
          [items]="unitsList"
          className="radius-10"
          formControlName="unitId"
          [searchable]="true"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('unitId')?.touched &&
              form.get('unitId')?.hasError('required')
            "
          >
            Address is required
          </p>
        </mat-error>
      </div>
    </div>

    <div class="mb-20px">
      <mat-label class="fw-400">
        Select type of modification that you are planning to do.
      </mat-label>

      <p class="roam-title grey-text mt-10px">Minor modifications</p>
      <div class="category mt-5px">
        <app-shortcut-menu-card
          *ngFor="let item of minorType"
          [shortcut]="item"
          (click)="borderColorChange(item)"
        ></app-shortcut-menu-card>
      </div>

      <p class="roam-title grey-text mt-20px">Moderate sized modifications</p>
      <div class="category mt-5px">
        <app-shortcut-menu-card
          *ngFor="let item of moderateType"
          [shortcut]="item"
          (click)="borderColorChange(item)"
        ></app-shortcut-menu-card>
      </div>

      <p class="roam-title grey-text mt-20px">Major modifications</p>
      <div class="category mt-5px">
        <app-shortcut-menu-card
          *ngFor="let item of majorType"
          [shortcut]="item"
          (click)="borderColorChange(item)"
        ></app-shortcut-menu-card>
      </div>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('category')?.touched &&
            form.get('category')?.hasError('required')
          "
        >
          Modification type is required
        </p>
      </mat-error>
    </div>

    <div *ngIf="userRole === 'tenant'" class="w-40 mb-20px">
      <app-roam-input
        name="Project name"
        formControlName="name"
      ></app-roam-input>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('name')?.touched && form.get('name')?.hasError('required')
          "
        >
          Project name is required
        </p>
      </mat-error>
    </div>

    <div *ngIf="userRole === 'tenant'" class="mb-20px">
      <mat-label class="fw-400">
        To resolve this matter promptly, please provide additional information
        on the modification that you are requesting.
      </mat-label>

      <mat-form-field appearance="outline" class="white-bg w-100">
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Description"
          formControlName="description"
        ></textarea>
      </mat-form-field>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('description')?.touched &&
            form.get('description')?.hasError('required')
          "
        >
          Description is required
        </p>
      </mat-error>
    </div>

    <div *ngIf="userRole === 'manager'" class="mb-20px">
      <mat-label class="fw-400"> Additional information (Optional) </mat-label>

      <mat-form-field appearance="outline" class="white-bg w-100">
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Additional information"
          formControlName="info"
        ></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-label class="fw-400"> Attachments </mat-label>

      <div *ngIf="files.length < 1" class="attachment c-pointer">
        <input type="file" id="uploadFile" (change)="selectedFile($event)" />
        <p>
          <span>Drag here</span> or
          <button
            mat-button
            class="save"
            (click)="openFileUpload('uploadFile')"
          >
            <img src="/assets/svg/upload-icon-black.svg" alt="Upload Icon" />
            Upload File
          </button>
        </p>
      </div>

      <div *ngIf="files.length > 0" class="attachment__view">
        <input type="file" id="uploadFile" (change)="selectedFile($event)" />
        <ng-container *ngFor="let file of files; let _fileIndex = index">
          <div class="attachment__view__file">
            <img
              [src]="
                file.type.includes('image')
                  ? '/assets/svg/image-icon.svg'
                  : '/assets/svg/file-icon.svg'
              "
              alt="Image Icon"
              style="margin-right: 10px"
            />
            <span>{{ file.name }}</span>
            <span class="vertical-line"></span>
            <img
              src="/assets/svg/trash-red-icon.svg"
              alt="Trash Icon"
              class="c-pointer"
              (click)="deleteFile(_fileIndex)"
            />
          </div>
        </ng-container>

        <button
          mat-button
          class="close-btn"
          style="color: rgba(145, 70, 106, 1)"
          (click)="openFileUpload('uploadFile')"
        >
          <img
            src="/assets/svg/plus-icon.svg"
            alt="Plus Icon"
            style="padding-right: 10px"
          />
          Add file
        </button>
      </div>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('uploadFile')?.touched &&
            form.get('uploadFile')?.hasError('required')
          "
        >
          Upload attachment is required
        </p>
      </mat-error>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="modification-dialog-actions">
  <button mat-button class="close-btn" mat-dialog-close>Cancel</button>
  <button mat-button class="continue-btn" (click)="onSubmit()">Create</button>
</mat-dialog-actions>
